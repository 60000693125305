.FloatingLabel_root__gmvjL {
  position: relative;
}

.FloatingLabel_label__tUpbc {
  --left-spacing: calc(var(--mantine-spacing-sm) + 6px);
  --top-spacing: calc(var(--mantine-spacing-sm));
}

.FloatingLabel_label__tUpbc[data-phone-input] {
    --left-spacing: calc(var(--mantine-spacing-sm) + 64px);
  }

.FloatingLabel_label__tUpbc {

  position: absolute;
  z-index: 2;
  top: var(--top-spacing);
  left: var(--left-spacing);
  pointer-events: none;
  color: var(--mantine-color-gray-5);
}

[data-mantine-color-scheme='dark'] .FloatingLabel_label__tUpbc {
  color: var(--mantine-color-dark-3);
}

.FloatingLabel_label__tUpbc {
  transition: transform 150ms ease, font-size 150ms ease, color 150ms ease;
}

.FloatingLabel_label__tUpbc[data-floating] {
    transform: translate(calc(var(--left-spacing) * -1), calc(-2rem * var(--mantine-scale)));
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;
    color: var(--mantine-color-black);
  }

[data-mantine-color-scheme='dark'] .FloatingLabel_label__tUpbc[data-floating] {
    color: var(--mantine-color-white);
}

.FloatingLabel_required__m_b_F {
  transition: opacity 150ms ease;
  opacity: 0;
}

[data-floating] .FloatingLabel_required__m_b_F {
    opacity: 1;
  }

.FloatingLabel_input__DSc1T::-moz-placeholder {
    -moz-transition: color 150ms ease;
    transition: color 150ms ease;
    color: transparent;
  }

.FloatingLabel_input__DSc1T::placeholder {
    transition: color 150ms ease;
    color: transparent;
  }

.FloatingLabel_input__DSc1T[data-floating]::-moz-placeholder {
      color: var(--mantine-color-placeholder);
    }

.FloatingLabel_input__DSc1T[data-floating]::placeholder {
      color: var(--mantine-color-placeholder);
    }

