.root {
  position: relative;
}

.label {
  --left-spacing: calc(var(--mantine-spacing-sm) + 6px);
  --top-spacing: calc(var(--mantine-spacing-sm));

  &[data-phone-input] {
    --left-spacing: calc(var(--mantine-spacing-sm) + 64px);
  }

  position: absolute;
  z-index: 2;
  top: var(--top-spacing);
  left: var(--left-spacing);
  pointer-events: none;
  color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3));
  transition: transform 150ms ease, font-size 150ms ease, color 150ms ease;

  &[data-floating] {
    transform: translate(calc(var(--left-spacing) * -1), rem(-32px));
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  }
}

.required {
  transition: opacity 150ms ease;
  opacity: 0;

  [data-floating] & {
    opacity: 1;
  }
}

.input {
  &::placeholder {
    transition: color 150ms ease;
    color: transparent;
  }

  &[data-floating] {
    &::placeholder {
      color: var(--mantine-color-placeholder);
    }
  }
}
